import React from "react";
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import RetinolPage from "./pages/ingredients/RetinolPage";
import VitamincPage from "./pages/ingredients/VitamincPage";
import NiacinamidePage from "./pages/ingredients/NiacinamidePage";
import CeramidePage from "./pages/ingredients/CeramidePage";
import HyaluronicPage from "./pages/ingredients/HyaluronicPage";
import AzelaicPage from "./pages/ingredients/AzelaicPage";
import GlycylglycinePage from "./pages/ingredients/GlycylglycinePage";
import TranexamicPage from "./pages/ingredients/TranexamicPage";
import GlycyrrhizicPage from "./pages/ingredients/GlycyrrhizicPage";
import DokudamiPage from "./pages/ingredients/DokudamiPage";
import AllantoinPage from "./pages/ingredients/AllantoinPage";
import FullerenePage from "./pages/ingredients/FullerenePage";
import NotFoundPage from "./pages/NotFoundPage"; // 追加

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <div>
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/ingredients/retinol" element={<RetinolPage />} />
                    <Route path="/ingredients/vitamin-c" element={<VitamincPage />} />
                    <Route path="/ingredients/niacinamide" element={<NiacinamidePage />} />
                    <Route path="/ingredients/ceramide" element={<CeramidePage />} />
                    <Route path="/ingredients/hyaluronic" element={<HyaluronicPage />} />
                    <Route path="/ingredients/azelaic" element={<AzelaicPage />} />
                    <Route path="/ingredients/glycylglycine" element={<GlycylglycinePage />} />
                    <Route path="/ingredients/tranexamic" element={<TranexamicPage />} />
                    <Route path="/ingredients/glycyrrhizic" element={<GlycyrrhizicPage />} />
                    <Route path="/ingredients/dokudami" element={<DokudamiPage />} />
                    <Route path="/ingredients/allantoin" element={<AllantoinPage />} />
                    <Route path="/ingredients/fullerene" element={<FullerenePage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default App;
