import { Product } from "../interfaces/Product";

export const filterProducts = {
    showAll: (products: Product[]): Product[] => products,

    filterByBooster: (products: Product[]): Product[] => {
        return products.filter((product) => product.type === "導入液");
    },

    filterByToner: (products: Product[]): Product[] => {
        return products.filter(
            (product) => product.type === "ミスト化粧水" || product.type === "化粧水"
        );
    },

    filterBySerum: (products: Product[]): Product[] => {
        return products.filter((product) => product.type === "美容液");
    },

    filterByCream: (products: Product[]): Product[] => {
        return products.filter((product) => product.type === "クリーム");
    },

    filterByEye: (products: Product[]): Product[] => {
        return products.filter((product) => product.type === "アイケア");
    },
};
