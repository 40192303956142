import React from "react";
import { IngreMenu } from "../components/IngreMenu";

const HomePage: React.FC = () => {
    return (
        <div className="lg-inner top-page">
            <h2 className="ttl-ul">成分からスキンケア製品を探す</h2>
            <IngreMenu />
        </div>
    );
};

export default HomePage;
