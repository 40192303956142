//その他データ
import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    brand: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand,
    name,
    folder: "others",
    image,
    comment,
    ...extraProps,
});

export const MedihealEssencePad = createProduct(
    "MedihealEssencePad",
    "MEDIHEAL(メディヒール)",
    "ティーツリーカーミングエッセンスパッド",
    `スキンケアの一番はじめに使用すると後から使用するスキンケアの浸透力が高まります。また、肌の油水分のバランスを整えたり、ターンオーバーを促進する効果もあるので、ニキビや毛穴の改善も期待できます。`,
    {
        type: "トナーパッド",
        ingredients: "アラントインヒアルロン酸ツボクサエキス",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">最近メディヒールのトナーパット使い始めたんだけど、今までの乾燥肌がまるで嘘かのようにスッピンがツヤッツヤなんだけど？？<br>韓国のオバ様によるとこれはパッドの中でも保湿力高いから冬におすすめらしい🤧つゆだく♡<br>とにかく化粧ノリが百億倍良くなって涙！ティーツリーはトラブルも抑えてくれる！ <a href="https://t.co/jdP0Z5hVMK">pic.twitter.com/jdP0Z5hVMK</a></p>&mdash; 糞美容垢のあおﾁｬﾝ🩵 (@kusobiyoakaka) <a href="https://twitter.com/kusobiyoakaka/status/1468160228927893504?ref_src=twsrc%5Etfw">December 7, 2021</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        qoo10: "https://www.qoo10.jp/su/1418359230/Q192046927",
    }
);

export const MeranoCC = createProduct(
    "MeranoCC",
    "メラノCC",
    "薬用しみ対策 美白化粧水",
    `顔全体にうるおいを与えながら、しみ対策できる薬用美白化粧水。
    <br>美白有効成分高浸透ビタミンC誘導体が角質層すみずみまで浸透し、メラニンの生成を抑え、しみ・そばかすを防ぎ、透明感のある肌へと導きます。
    <br>また、ニキビ痕が気になる肌にもうるおいを与え、肌を整えます。さわやかな柑橘系の香りです`,
    {
        type: "化粧水",
        ingredients: "ビタミンC誘導体グリチルリチン酸ジカリウム",
        amazon: "https://amzn.to/4coeRgK",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">メラノCCリピリピ〜🍋<br>朝はコレに限る。バシャバシャいく。デコルテにも腕にも惜しげもなくいく。🤣スーパー銭湯行った時に美肌のおばちゃんが化粧水お風呂上がりに全身つけてはって真似してんねん🥹🫰🏻笑銭湯に来てるおばちゃんもおばあちゃんもほんまに色白美肌…✨温泉と化粧水の効果すごいな🤤 <a href="https://t.co/NKl69vRFfR">pic.twitter.com/NKl69vRFfR</a></p>&mdash; 𝚗𝚢𝚊𝚖𝚒🤍ིྀ (@nyanmoconyan) <a href="https://twitter.com/nyanmoconyan/status/1520190241163808768?ref_src=twsrc%5Etfw">April 29, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const IpsaTheTimeAqua = createProduct(
    "IpsaTheTimeAqua",
    "IPSA(イプサ)",
    "ザ・タイムR アクア",
    "",
    {
        type: "化粧水",
        features: "アルコールフリーオイルフリー無香料無着色",
        ingredients: "トラネキサム酸グリチルリチン酸2K",
        amazon: "https://amzn.to/4cH85m3",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">イプサのザ・タイムR アクアの化粧水とっても良かったなあ<br>敏感肌な方なので油分多めだったり変な成分入ってるとすぐ炎症起こす&amp;ニキビが大量発生するんだけど、イプサはそれが無いのにしっとりしたつけ心地でちゃんと保湿してくれた🥰かみさま…<br>もうすぐ1本使い終わるけどまたリピートしたい！ <a href="https://t.co/u3cfqvY9Y8">pic.twitter.com/u3cfqvY9Y8</a></p>&mdash; そう、これは すず (@suu_meh) <a href="https://twitter.com/suu_meh/status/1265076810661818373?ref_src=twsrc%5Etfw">May 26, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const PlusGGBaseSerum = createProduct(
    "PlusGGBaseSerum",
    "プラスキレイ",
    "プラスGGベースセラム",
    "",
    {
        type: "導入液",
        ingredients:
            "グリシルグリシンナイアシンアミドビタミンＣ誘導体APPSヒト型セラミドヒアルロン酸",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">バズってないがバズってもいいのではと思ってるコスメ「プラスggベースセラムローション」<br>毛穴目立ちは毛穴周りの皮膚の肌荒れから。毛穴ケアにエビデンスのあるグリシルグリシン6.6%配合の先行美容液。ナイアシンアミドにヒト型セラミドアゼライン酸誘導体まで入ってなかなか良いのに知名度低すぎ。 <a href="https://t.co/qTtKH2MmOA">pic.twitter.com/qTtKH2MmOA</a></p>&mdash; ななな｜脱ファンデ計画 (@sonokyomunikiku) <a href="https://twitter.com/sonokyomunikiku/status/1707223107914887615?ref_src=twsrc%5Etfw">September 28, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const FirstSpraySerum = createProduct(
    "FirstSpraySerum",
    "ダルバ",
    "ホワイトトリュフファーストスプレーセラム",
    "",
    {
        type: "ミスト化粧水",
        ingredients: "ナイアシンアミドグリチルリチン酸2Kドクダミエキス",
        amazon: "https://amzn.to/4eHJdfD",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">メガ割で買い足そうと思ってるのがCAミストとして有名なダルバのスプレー。<br>化粧水とオイルの2層式になっててしっとり保湿されるの🫶🏻霧みたいに粒子が細かいからメイクの上から仕上げにひと吹きしたり乾燥が気になる時に使ってる！艶々になる！香りもとっても良い！ <a href="https://t.co/eGQJ9dhNMa">pic.twitter.com/eGQJ9dhNMa</a></p>&mdash; 松川星 (@akarin__rin) <a href="https://twitter.com/akarin__rin/status/1796845537762926608?ref_src=twsrc%5Etfw">June 1, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const PeptideVolume = createProduct(
    "PeptideVolume",
    "ドクターペプチ",
    "ペプチド ボリューム マスター エッセンス",
    "",
    {
        type: "美容液",
        ingredients: "ペプチドグルタチオンヒト型セラミドアラントイン",
        amazon: "https://amzn.to/3RUQj6L",
        qoo10: "https://www.qoo10.jp/su/1419182645/Q192046927",
    }
);

export const EtvosCreamGel = createProduct(
    "EtvosCreamGel",
    "エトヴォス",
    "セラミドスキンケア 薬用アクネVCクリームジェル",
    "",
    {
        type: "美容液",
        ingredients: "グリチルリチン酸2KビタミンC誘導体ヒアルロン酸ヒト型セラミド",
        amazon: "https://amzn.to/3L8Vlcg",
        features: "オイルフリーアルコールフリー",
    }
);

export const HadalaboHakujunPremiem = createProduct(
    "HadalaboHakujunPremiem",
    "肌ラボ",
    "白潤プレミアム薬用浸透美白化粧水",
    "",
    {
        type: "化粧水",
        amazon: "https://amzn.to/4bxkaJj",
        ingredients: "トラネキサム酸グリチルリチン酸2KビタミンC誘導体ヒアルロン酸",
        features: "無香料無着色アルコールフリー",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">コスパが良くいいスキンケア用品を探して行き着いたのがこれ。この度4本目のリピ、白潤プレミアムの化粧水。<br><br>トラネキサム酸、グリチルリチン酸2K、ヒアルロン酸が入って1,000円切る高コスパ💰<br>さっぱりタイプを買って浴びるようにつけてる。首やデコルテにも。<br><br>美白抗炎症ばんざい🙌ロート製薬強い <a href="https://t.co/3aLpNwG84d">pic.twitter.com/3aLpNwG84d</a></p>&mdash; しとね🌸 (@bea_shi_uty) <a href="https://twitter.com/bea_shi_uty/status/1442491325970935818?ref_src=twsrc%5Etfw">September 27, 2021</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const IhadaLotion = createProduct("IhadaLotion", "イハダ(資生堂)", "薬用ローション", "", {
    type: "化粧水",
    ingredients: "アラントイングリチルリチン酸2K",
    amazon: "https://amzn.to/3L7Gv5y",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">「ニキビやば〜〜😭😭」って時にイハダの化粧水と乳液を使うと、みるみるうちにニキビが無くなっていくのほんっっっと凄い。毎回ビビる。<br>抗炎症成分が2種類入ってるから肌荒れがおさまるのが早い....てか、2つ買っても3500円くらいって安すぎ。デパコス価格になっても買うもん。 <a href="https://t.co/20Ws0lp3LU">pic.twitter.com/20Ws0lp3LU</a></p>&mdash; ご醜傷様ちゃん (@syusyochan_) <a href="https://twitter.com/syusyochan_/status/1671848602422116352?ref_src=twsrc%5Etfw">June 22, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    type1Title: "しっとりタイプ",
});
