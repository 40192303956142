import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { ProductTable } from "../../components/ProductTable";
import { filterProducts } from "../../components/FilterProducts";
import { Product } from "../../interfaces/Product";
import * as productData from "./NiacinamideData";

const RetinolPage: React.FC = () => {
    const pageTitle = "フラーレン";
    const products: Product[] = Object.values(productData);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
    const [activeFilter, setActiveFilter] = useState<string>("all");

    const handleShowAll = () => {
        setFilteredProducts(filterProducts.showAll(products));
        setActiveFilter("all");
    };

    const handleFilterByMist = () => {
        setFilteredProducts(filterProducts.filterByToner(products));
        setActiveFilter("toner");
    };

    const handleFilterBySerum = () => {
        setFilteredProducts(filterProducts.filterBySerum(products));
        setActiveFilter("serum");
    };

    return (
        <>
            <PageTitle title={pageTitle} />
            <div className="products-page lg-inner">
                <h1>{pageTitle}</h1>
                <div className="lead">
                    {/* 
 <h2 className="ttl-ul">主な働き</h2>

                <h2 className="ttl-ul">こんな肌悩みにおすすめ</h2>
                      lead 
                <ul className="problem-list">
                    <li>赤み</li>
                    <li>ニキビ</li>
                    <li>小ジワ</li>
                </ul>*/}
                </div>

                <h2 className="ttl-ul">{`${pageTitle}を含んだ製品一覧`}</h2>

                <div className="filters">
                    <button
                        onClick={handleShowAll}
                        className={activeFilter === "all" ? "active" : ""}
                    >
                        すべて
                    </button>
                    <button
                        onClick={handleFilterByMist}
                        className={activeFilter === "toner" ? "active" : ""}
                    >
                        化粧水
                    </button>
                    <button
                        onClick={handleFilterBySerum}
                        className={activeFilter === "serum" ? "active" : ""}
                    >
                        美容液
                    </button>
                </div>

                <ul className="products-list">
                    {filteredProducts.map((product, index) => (
                        <ProductTable key={index} product={product} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default RetinolPage;
