import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
) => ({
    brand: "トゥベール",
    name,
    folder: "toutvert",
    image,
    comment,
    ...extraProps,
});

export const RetinoShots = createProduct("RetinoShots", "レチノショット", "", {
    type: "クリーム",
    ingredients: "レチノール",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">スキンケアにレチノールを取り入れてて、ゼオもエンビロンもその他諸々使ってきたけど、トゥヴェールのレチノショット凄い！！個人的に今まで使ってきたレチノールの中で1番🥇寝起きでもハリのあるぷりぷりピカピカ肌に！！効果的な分、私は連続使用で最初少し皮剥けたから慎重に使った方がいいかも... <a href="https://t.co/qg4y35ZLXD">pic.twitter.com/qg4y35ZLXD</a></p>&mdash; ご醜傷様ちゃん (@syusyochan_) <a href="https://twitter.com/syusyochan_/status/1455687833457356803?ref_src=twsrc%5Etfw">November 3, 2021</a></blockquote>`,
});

export const BalancingGA = createProduct("BalancingGA", "バランシングGAローション", "", {
    type: "導入液",
    ingredients: "グリチルリチン酸2Kアゼライン酸グリシルグリシン",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">毛穴みかんの人はアゼ×グリシルグリシン！！！！！去年から言い続けてるけどな！！！！周りの美容ナースも使ってる成分デパコスプチプラブランド！！！美容医療だけに縋りついても日々のケアで良い成分使ってないと毛穴無理だから！！！しつこく言うけどまじでおすすめ！！！！ <a href="https://t.co/TBQec6w4zB">pic.twitter.com/TBQec6w4zB</a></p>&mdash; らびめろ。ナース (@lavi8mero) <a href="https://twitter.com/lavi8mero/status/1796518564289220695?ref_src=twsrc%5Etfw">May 31, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
});

export const NanoEmulsion = createProduct("NanoEmulsion", "ナノエマルジョン", "", {
    type: "乳液",
    ingredients: "セラミド",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">セラミドをナノ化したこちらに関しては知り合いの美容皮膚科の先生も、保湿剤？いやこれ使っとけばええで、と言うレベルの恐ろしい商品なのよね（深夜だからボソっとﾂｲｰﾄ） <a href="https://t.co/xaH9x3Nz6s">pic.twitter.com/xaH9x3Nz6s</a></p>&mdash; らびめろ。ナース (@lavi8mero) <a href="https://twitter.com/lavi8mero/status/1771548794737324131?ref_src=twsrc%5Etfw">March 23, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
});

export const CrystalEssence = createProduct("CrystalEssence", "クリスタルエッセンス", "", {
    type: "美容液",
    ingredients: "ナイアシンアミドヒト型セラミドフラーレン",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">スキンケア難民へ〜〜〜！「トゥベール」は特に私の周りの現役美容ナースに愛用者多い（彼女たちは美容医療に重課金しているけど家でのケアも完璧で信頼していい商品だと個人的に思ってます）（何度もバズる私が一番好きなナイアシンアミドとビタミンCの美容液） <a href="https://t.co/Q8MBWTbpOG">pic.twitter.com/Q8MBWTbpOG</a></p>&mdash; らびめろ。ナース (@lavi8mero) <a href="https://twitter.com/lavi8mero/status/1660956530437656576?ref_src=twsrc%5Etfw">May 23, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
});
