// src/components/PageTitle.tsx
import React from "react";
import { Helmet } from "react-helmet-async";

interface PageTitleProps {
    title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
    return (
        <Helmet>
            <title>{`美容成分${title}を含むスキンケア製品の一覧と成分の特徴｜素肌研究所`}</title>
        </Helmet>
    );
};

export default PageTitle;
