import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Product } from "../interfaces/Product";

interface ProductTableProps {
    product: Product;
}

export const ProductTable: React.FC<ProductTableProps> = ({ product }) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://platform.twitter.com/widgets.js";
        script.charset = "utf-8";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [product.twitterEmbed]);

    const renderIngredients = (ingredients?: string) => {
        if (!ingredients) return null;

        let modifiedIngredients = ingredients.replace(
            "ヒト型セラミド",
            '<li><a href="/ingredients/ceramide">ヒト型セラミド</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "レチノール",
            '<li><a href="/retinol">レチノール</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "ビタミンC",
            '<li><a href="/ingredients/vitamin-c">ビタミンC</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "ナイアシンアミド",
            '<li><a href="/ingredients/niacinamide">ナイアシンアミド</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "ヒアルロン酸",
            '<li><a href="/ingredients/hyaluronic">ヒアルロン酸</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "アゼライン酸",
            '<li><a href="/ingredients/azelaic">アゼライン酸</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "グリシルグリシン",
            '<li><a href="/ingredients/glycylglycine">グリシルグリシン</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "4MSK",
            '<li><a href="/ingredients/4msk">4MSK</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "トラネキサム酸",
            '<li><a href="/ingredients/tranexamic">トラネキサム酸</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "グリチルリチン酸2K",
            '<li><a href="/ingredients/glycyrrhizic">グリチルリチン酸2K</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace(
            "ドクダミエキス",
            '<li><a href="/ingredients/dokudami">ドクダミエキス</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace("グルタチオン", "<li>グルタチオン</li>");
        modifiedIngredients = modifiedIngredients.replace("パンテノール", "<li>パンテノール</li>");
        modifiedIngredients = modifiedIngredients.replace(
            "アラントイン",
            '<li><a href="/ingredients/allantoin">アラントイン</a></li>'
        );
        modifiedIngredients = modifiedIngredients.replace("アルブチン", "<li>アルブチン</li>");

        return <ul dangerouslySetInnerHTML={{ __html: modifiedIngredients }} />;
    };

    return (
        <li className="item">
            <div className="heading">
                <figure>
                    <img
                        src={`/images/${product.folder}/${product.image}.jpg`}
                        alt={product.name}
                        loading="lazy"
                        className="products"
                    />
                </figure>
                <div className="detail">
                    {/* 基本情報 */}
                    <div className="basic">
                        {product.type && <p className="type">{product.type}</p>}
                        <p className="from">{product.brand}</p>
                        <p className="ttl">{product.name}</p>
                    </div>

                    <div className="cta-top">
                        {product.qoo10 && (
                            <Link to={product.qoo10} className="ctaButton qoo10">
                                Qoo10で価格を見る
                            </Link>
                        )}
                        {product.amazon && (
                            <Link to={product.amazon} className="ctaButton amazon">
                                Amazonで価格を見る
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            {product.ingredients && (
                <div className="ingre">
                    <h3>主な成分</h3> {renderIngredients(product.ingredients)}
                </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: product.comment }} />
            {product.type1Title && <span>{product.type1Title}</span>}
            <div className="cta">
                {product.qoo10 && (
                    <Link to={product.qoo10} className="ctaButton qoo10">
                        Qoo10で価格を見る
                    </Link>
                )}
                {product.amazon && (
                    <Link to={product.amazon} className="ctaButton amazon">
                        Amazonで価格を見る
                    </Link>
                )}
            </div>
            {product.type2Title && (
                <>
                    <span>{product.type2Title}</span>
                    <div className="cta">
                        {product.qoo102 && (
                            <Link to={product.qoo102} className="ctaButton qoo10">
                                Qoo10で価格を見る
                            </Link>
                        )}
                        {product.amazon2 && (
                            <Link to={product.amazon2} className="ctaButton">
                                Amazonで価格を見る
                            </Link>
                        )}
                    </div>
                </>
            )}

            {product.type3Title && (
                <>
                    <span>{product.type3Title}</span>
                    <div className="cta">
                        {product.qoo103 && (
                            <Link to={product.qoo103} className="ctaButton qoo10">
                                Qoo10で価格を見る
                            </Link>
                        )}
                        {product.amazon3 && (
                            <Link to={product.amazon3} className="ctaButton">
                                Amazonで価格を見る
                            </Link>
                        )}
                    </div>
                </>
            )}

            {product.twitterEmbed && (
                <div dangerouslySetInnerHTML={{ __html: product.twitterEmbed }} />
            )}
        </li>
    );
};
