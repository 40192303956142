// src/pages/ingredients/RetinolPage.tsx
import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { ProductTable } from "../../components/ProductTable";
import { filterProducts } from "../../components/FilterProducts";
import { Product } from "../../interfaces/Product";
import * as productData from "./RetinolData";

const RetinolPage: React.FC = () => {
    const pageTitle = "レチノール";
    const products: Product[] = Object.values(productData);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
    const [activeFilter, setActiveFilter] = useState<string>("all");

    const handleShowAll = () => {
        setFilteredProducts(filterProducts.showAll(products));
        setActiveFilter("all");
    };

    const handleFilterBySerum = () => {
        setFilteredProducts(filterProducts.filterBySerum(products));
        setActiveFilter("serum");
    };

    const handleFilterByCream = () => {
        setFilteredProducts(filterProducts.filterByCream(products));
        setActiveFilter("cream");
    };

    const handleFilterByEye = () => {
        setFilteredProducts(filterProducts.filterByEye(products));
        setActiveFilter("eye");
    };

    return (
        <>
            <PageTitle title={pageTitle} />
            <div className="products-page lg-inner">
                <h1>{pageTitle}</h1>
                <div className="lead">
                    <h2 className="ttl-ul">{`${pageTitle}を含んだ製品一覧`}</h2>
                    <p>
                        <span className="_bold">ターンオーバー促進</span>
                        レチノールは肌の生まれ変わりをサポートし、古い角質を取り除いて新しい肌を作り出す助けになります。毛穴の詰まりを防いで毛穴が目立ちにくくなり、ニキビの発生を抑えます。しみやくすみが軽減される効果も期待されます。
                        <span className="mt _bold">肌の弾力サポート</span>
                        コラーゲンの生成を助けると言われており、肌のハリを保つのに役立ちます。これにより、肌がより引き締まり、毛穴を引き締める効果が期待できます。
                    </p>

                    <h2>こんな肌悩みにおすすめ</h2>
                    <ul className="problem-list">
                        <li>詰まり毛穴</li>
                        <li>たるみ毛穴</li>
                        <li>ニキビ</li>
                        <li>しみ</li>
                        <li>くすみ</li>
                    </ul>
                </div>
                {/* lead */}

                <h2 className="ttl-ul">レチノールを含んだ製品一覧</h2>

                <div className="filters">
                    <button
                        onClick={handleShowAll}
                        className={activeFilter === "all" ? "active" : ""}
                    >
                        すべて
                    </button>
                    <button
                        onClick={handleFilterBySerum}
                        className={activeFilter === "serum" ? "active" : ""}
                    >
                        美容液
                    </button>
                    <button
                        onClick={handleFilterByCream}
                        className={activeFilter === "cream" ? "active" : ""}
                    >
                        クリーム
                    </button>
                    <button
                        onClick={handleFilterByEye}
                        className={activeFilter === "eye" ? "active" : ""}
                    >
                        アイケア
                    </button>
                </div>

                <ul className="products-list">
                    {filteredProducts.map((product, index) => (
                        <ProductTable key={index} product={product} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default RetinolPage;
