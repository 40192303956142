import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { ProductTable } from "../../components/ProductTable";
import { filterProducts } from "../../components/FilterProducts";
import { Product } from "../../interfaces/Product";
import * as productData from "./AllantoinData";

const RetinolPage: React.FC = () => {
    const products: Product[] = Object.values(productData);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
    const [activeFilter, setActiveFilter] = useState<string>("all");

    const handleShowAll = () => {
        setFilteredProducts(filterProducts.showAll(products));
        setActiveFilter("all");
    };

    const handleFilterByMist = () => {
        setFilteredProducts(filterProducts.filterByToner(products));
        setActiveFilter("toner");
    };

    const handleFilterBySerum = () => {
        setFilteredProducts(filterProducts.filterBySerum(products));
        setActiveFilter("serum");
    };

    const handleFilterByCream = () => {
        setFilteredProducts(filterProducts.filterByCream(products));
        setActiveFilter("cream");
    };

    return (
        <>
            <PageTitle title="アラントイン" />
            <div className="products-page lg-inner">
                <h1>アラントイン</h1>
                <div className="lead">
                    <h2 className="ttl-ul">アラントインの主な働き</h2>
                    <span className="_bold">抗炎症作用</span>
                    <p>
                        肌の赤みや腫れを和らげ、炎症を抑える効果があります。
                        <br />
                        アクネ菌による炎症を抑える効果も期待されているため、ニキビケアに効果的です。
                        <br />
                        日焼け後のほてりを鎮めて、日焼けで炎症や刺激を受けた肌を穏やかに落ち着かせる効果もあります。
                        <br />
                    </p>
                    <span className="_bold mt">ターンオーバー促進作用</span>
                    <p>
                        古い角質を取り除き、新しい皮膚細胞の再生を助ける効果があります。これにより、ニキビ跡や肌のざらつきやくすみを改善し、滑らかで明るい肌を保ちます。
                    </p>

                    <h2 className="ttl-ul">こんな肌悩みにおすすめ</h2>
                    <ul className="problem-list">
                        <li>ニキビ</li>
                        <li>ニキビ跡</li>
                        <li>日焼け</li>
                        <li>くすみ</li>
                    </ul>
                </div>
                {/* lead */}

                <h2 className="ttl-ul">アラントインを含んだ製品一覧</h2>

                <div className="filters">
                    <button
                        onClick={handleShowAll}
                        className={activeFilter === "all" ? "active" : ""}
                    >
                        すべて
                    </button>
                    <button
                        onClick={handleFilterByMist}
                        className={activeFilter === "toner" ? "active" : ""}
                    >
                        化粧水
                    </button>
                    <button
                        onClick={handleFilterBySerum}
                        className={activeFilter === "serum" ? "active" : ""}
                    >
                        美容液
                    </button>
                    <button
                        onClick={handleFilterByCream}
                        className={activeFilter === "cream" ? "active" : ""}
                    >
                        クリーム
                    </button>
                </div>

                <ul className="products-list">
                    {filteredProducts.map((product, index) => (
                        <ProductTable key={index} product={product} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default RetinolPage;
