interface Product {
    brand: string;
    name: string;
    folder: string;
    image: string;
    comment: string;
    ingredients?: string;
    twitterEmbed?: string;
    type: string;
    qoo10?: string;
    amazon?: string;
    rakuten?: string;
    yahoo?: string;
}

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "資生堂アクアレーベル",
    name,
    folder: "aqualabel",
    image,
    comment,
    type,
    ...extraProps,
});

export const BritningCareLotion = createProduct(
    "BritningCareLotion",
    "化粧水",
    "ブライトニングケア ローション",
    "",
    {
        ingredients: "トラネキサム酸コラーゲン",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">クレドの美白化粧水と同じ成分なら<br>アクアレーベルの青でいいじゃん最高👶🏻🎶 <a href="https://t.co/FexCCcX2kG">pic.twitter.com/FexCCcX2kG</a></p>&mdash; 𝐒𝐇𝐈𝐑𝐀𝐓𝐀𝐊𝐈 (@picopucopaco) <a href="https://twitter.com/picopucopaco/status/1767866244433322016?ref_src=twsrc%5Etfw">March 13, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        amazon: "https://amzn.to/3XHkja3",
    }
);
