import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "numbuzin(ナンバーズイン)",
    name,
    folder: "numbuzin",
    image,
    comment,
    type,
    ...extraProps,
});

export const EssentialToner = createProduct(
    "EssentialToner",
    "化粧水",
    "5番 白玉グルタチオンCエッセンシャルトナー",
    "",
    {
        qoo10: "https://www.qoo10.jp/su/1419182722/Q192046927",
        ingredients: "グルタチオンナイアシンアミド",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">ナンバーズインの化粧水◯ぬほどいい。美容液レベルにとろみあるのに秒で浸透して時短だし、成分がグルタチオン、ビタミンC、ナイアシンアミドと絶対白くなるやろ❕というラインナップ。1週間ぶりに会った母に「そんな白かった？」って言われたから効果も秒。これで2千円はデパコス泣かせですよ... <a href="https://t.co/IZJSIZ3Y42">pic.twitter.com/IZJSIZ3Y42</a></p>&mdash; タフ子ちゃん🐟 (@llx_ayu_xll) <a href="https://twitter.com/llx_ayu_xll/status/1793251560740581823?ref_src=twsrc%5Etfw">May 22, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const ShiratamaSerum = createProduct(
    "ShiratamaSerum",
    "美容液",
    "5番 白玉グルタチオンＣ美容液",
    "",
    {
        ingredients: "グルタチオントラネキサム酸ナイアシンアミドビタミンC",
        qoo10: "https://www.qoo10.jp/su/1419182750/Q192046927",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">もう何回もバズり散らかしてるけど何回でも言う。ナンバーズインの５番をライン使いした日の肌は肌管理後以上の艶感と質感だから本ｯｯｯ当にオススメ。 <a href="https://t.co/HAsNhfpLWU">pic.twitter.com/HAsNhfpLWU</a></p>&mdash; 𝖬𝗈𝖼𝗁𝗂𝗂 (@mochi__cosme) <a href="https://twitter.com/mochi__cosme/status/1764297047996526885?ref_src=twsrc%5Etfw">March 3, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const FurikakeMask = createProduct(
    "FurikakeMask",
    "シートマスク",
    "5番 白玉グルタチオンCふりかけマスク",
    "顔全面に隙間なく照明ON！ビタミンCをふりかけた黄色いシートに高濃縮グルタチオンC美容液を入れ込んだ、くすみ集中ケアシートマスク。",
    {
        qoo10: "https://www.qoo10.jp/su/1419182768/Q192046927",
        ingredients: "ナイアシンアミドヒト型セラミドヒアルロン酸ツボクサエキス",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">3の数字で有名なnumbuzin(ナンバーズイン)から新マスクパックが登場💛💛<br>その名は５番白玉グルタチオンC「ふりかけ」マスク🍋<br>人工色素が含まれていないのにビックリするほどな黄色いマスクにはビタミンCがたっぷりふりかけられているの🥹このマスクで肌のトーンアップを叶えちゃおう✨ <a href="https://t.co/1SXkijq54p">pic.twitter.com/1SXkijq54p</a></p>&mdash; PowderRoom JAPAN 公式アカウント (@powderroom_jp) <a href="https://twitter.com/powderroom_jp/status/1754441511436263613?ref_src=twsrc%5Etfw">February 5, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);
