import React, { useEffect } from "react";
import { IngreMenu } from "../components/IngreMenu";

const Footer: React.FC = () => {
    useEffect(() => {
        const ingreBtn = document.querySelector(".ingre-btn");
        const probBtn = document.querySelector(".prob-btn");
        const ingreMenu = document.getElementById("ingre-menu");
        const probMenu = document.getElementById("prob-menu");
        const closeButtons = document.querySelectorAll(".close");

        // 初期状態ではメニューを非表示に設定
        ingreMenu?.classList.remove("active");
        probMenu?.classList.remove("active");

        const handleIngreClick = (event: Event) => {
            event.preventDefault(); // デフォルトのリンク動作を無効化
            if (ingreMenu?.classList.contains("active")) {
                ingreMenu.classList.remove("active");
                setTimeout(() => {
                    if (ingreMenu) ingreMenu.style.display = "none";
                }, 500);
            } else {
                if (ingreMenu) ingreMenu.style.display = "block";
                requestAnimationFrame(() => {
                    ingreMenu?.classList.add("active");
                });
            }
            probMenu?.classList.remove("active");
            setTimeout(() => {
                if (probMenu) probMenu.style.display = "none";
            }, 500);
        };

        const handleProbClick = (event: Event) => {
            event.preventDefault(); // デフォルトのリンク動作を無効化
            if (probMenu?.classList.contains("active")) {
                probMenu.classList.remove("active");
                setTimeout(() => {
                    if (probMenu) probMenu.style.display = "none";
                }, 500);
            } else {
                if (probMenu) probMenu.style.display = "block";
                requestAnimationFrame(() => {
                    probMenu?.classList.add("active");
                });
            }
            ingreMenu?.classList.remove("active");
            setTimeout(() => {
                if (ingreMenu) ingreMenu.style.display = "none";
            }, 500);
        };

        const handleCloseClick = (event: Event) => {
            const menuList = (event.target as HTMLElement).closest(".menu-list") as HTMLElement;
            if (menuList) {
                menuList.classList.remove("active");
                setTimeout(() => {
                    menuList.style.display = "none";
                }, 500);
            }
        };

        ingreBtn?.addEventListener("click", handleIngreClick);
        probBtn?.addEventListener("click", handleProbClick);
        closeButtons.forEach((button) => {
            button.addEventListener("click", handleCloseClick);
        });

        // クリーンアップ関数
        return () => {
            ingreBtn?.removeEventListener("click", handleIngreClick);
            probBtn?.removeEventListener("click", handleProbClick);
            closeButtons.forEach((button) => {
                button.removeEventListener("click", handleCloseClick);
            });
        };
    }, []);

    return (
        <section className="bottom-nav">
            <nav className="lg-inner">
                <ul className="nav-list">
                    <li className="item">
                        <button className="ingre-btn">成分から探す</button>
                        <div className="menu-list" id="ingre-menu">
                            <div className="container lg-inner">
                                <h2>
                                    スキンケア製品を
                                    <br />
                                    成分から探す
                                </h2>
                                <IngreMenu />
                                <button className="close">
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li className="item">
                        <button className="prob-btn">お肌のお悩みから探す</button>
                        <div className="menu-list" id="prob-menu">
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </section>
    );
};

export default Footer;
