import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
) => ({
    brand: "ドクターシーラボ",
    name,
    folder: "drcilabo",
    image,
    comment,
    ...extraProps,
});

export const VC1000 = createProduct("VC1000", "VC100エッセンスローションEX", "", {
    type: "化粧水",
    ingredients: "ビタミンCナイアシンアミドヒアルロン酸セラミドフラーレン",
    amazon: "https://amzn.to/3VP8GLp",
    qoo10: "https://www.qoo10.jp/su/1419182516/Q192046927",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">ドクターシーラボのビタミンC化粧水凄かった話を聞いて欲しい。1ヶ月使ったらニキビが全然できなくなったし、毛穴もキュッと引き締まって肌も滑らかでもっちもちに。保湿力もしっかりあるので肌も全然乾燥しない。2/17からビタミンC2倍になってリニューアルらしいので絶対買います… <a href="https://t.co/h4UOSIgvaQ">pic.twitter.com/h4UOSIgvaQ</a></p>&mdash; あまなつ (@AmaNatsukoi) <a href="https://twitter.com/AmaNatsukoi/status/1493160551023005702?ref_src=twsrc%5Etfw">February 14, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
});
