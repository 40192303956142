interface Product {
    brand: string;
    name: string;
    folder: string;
    image: string;
    comment: string;
    ingredients?: string;
    twitterEmbed?: string;
    type: string;
    qoo10?: string;
    amazon?: string;
    rakuten?: string;
    yahoo?: string;
}

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "キュレル",
    name,
    folder: "curel",
    image,
    comment,
    type,
    ...extraProps,
});

export const DeepMoistureSpray = createProduct(
    "DeepMoistureSpray",
    "ミスト化粧水",
    "ディープモイスチャースプレー",
    "",
    {
        ingredients: "セラミドアラントイン",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">通ってる美容皮膚科のナースさん、全身ピッカピカツヤツヤ肌で超可愛くて😭💓美肌の秘訣を聞いたら「キュレルのスプレーを顔にも身体にも吹き付けてる」「ミニサイズを持ち歩いて日中メイクの上からも保湿してる」とのこと.....速攻で真似したら史上最強のつやんつやん肌ができたので布教させて..... <a href="https://t.co/ia7nosRLlw">pic.twitter.com/ia7nosRLlw</a></p>&mdash; ご醜傷様ちゃん (@syusyochan_) <a href="https://twitter.com/syusyochan_/status/1701553506845667697?ref_src=twsrc%5Etfw">September 12, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        amazon: "https://amzn.to/4ckojRI",
    }
);

export const FaceCream = createProduct("FaceCream", "クリーム", "潤浸保湿 フェイスクリーム", "", {
    ingredients: "疑似セラミドアラントイン",
    amazon: "https://amzn.to/3VQkKwb",
});
