import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
) => ({
    brand: "無印良品",
    name,
    folder: "muji",
    image,
    comment,
    type,
    ...extraProps,
});

export const WrinkleCare = createProduct(
    "WrinkleCare",
    "アイケア",
    "リンクルケアクリームマスク",
    "有効成分としてナイアシンアミド配合でシワを改善します。乾燥が気になる肌をもっちりハリのある肌に仕上げます。化粧水や乳液、美容液等の後、お手入れの最後にご使用ください。顔全体にさくらんぼ粒ぐらいを目安に厚く塗り広げてください。",
    {
        ingredients: "ナイアシンアミドヒアルロン酸コラーゲン",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">バズってた無印のフェイスクリーム、次の日お肌がしっとりふかふかで本気でびっくりしてまじで「え！？」って声出た。「10倍の値段がついてもおかしくない」ってツイートされてたけどガチでこれデパコス越え…ナイアシンアミド配合だからもっちりハリのある肌になる。スキンケア難民はとりあえずこれ <a href="https://t.co/BVxx2rcyrQ">pic.twitter.com/BVxx2rcyrQ</a></p>&mdash; 𝙡𝙚𝙢𝙤𝙣 🍋 (@garigari_mezas) <a href="https://twitter.com/garigari_mezas/status/1671098541073391620?ref_src=twsrc%5Etfw">June 20, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);

export const BoosterSerum = createProduct(
    "BoosterSerum",
    "導入液",
    "発酵導入美容液",
    `岩手県釜石の天然水を使用したスキンケアシリーズです。化粧水の前に使用して、美肌成分が浸透しやすい肌に整えるプレ化粧水です。
天然うるおい成分としてツバキ種子エキスなど5種類の植物エキスとアンズ果汁を配合しました`,
    {
        ingredients: "グリチルリチン酸2Kヒアルロン酸ヒト型セラミド",
        features: "無香料無鉱物油無着色弱酸性パラベンフリーアルコールフリー",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">3ヶ月ぶりくらいに会った友人、何か美容医療した？くらいめっちゃくちゃ お肌キレイになってて😳︎💕︎︎<br>友人曰く、無印の発行導入美容液使ってから肌質めっちゃ良くなったらしい。<br><br>無印何回行っても売り切れで諦めてたけど、友人のお肌のキレイさに我慢できず、オンラインから即ポチ！ﾁｮﾛｲｾﾞ <a href="https://t.co/FBdxwMemlA">pic.twitter.com/FBdxwMemlA</a></p>&mdash; まさこ (@ma0226411) <a href="https://twitter.com/ma0226411/status/1744364111696208256?ref_src=twsrc%5Etfw">January 8, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);
