// src/components/MyComponent.tsx
import React from "react";

export const IngreMenu: React.FC = () => {
    return (
        <ul className="ingre-menu-list">
            <li>
                <a href="/ingredients/retinol">レチノール</a>
            </li>
            <li>
                <a href="/ingredients/vitamin-c">ビタミンC</a>
            </li>
            <li>
                <a href="/ingredients/ceramide">セラミド</a>
            </li>
            <li>
                <a href="/ingredients/niacinamide">ナイアシンアミド</a>
            </li>
            <li>
                <a href="/ingredients/hyaluronic">ヒアルロン酸</a>
            </li>
            <li>
                <a href="/ingredients/azelaic">アゼライン酸</a>
            </li>
            <li>
                <a href="/ingredients/glycylglycine">グリシルグリシン</a>
            </li>
            <li>
                <a href="/ingredients/fullerene">フラーレン</a>
            </li>
            <li>
                <a href="/ingredients/tranexamic">トラネキサム酸</a>
            </li>
            <li>
                <a href="/ingredients/glycyrrhizic">グリチルリチン酸2K</a>
            </li>
            <li>
                <a href="/ingredients/allantoin">アラントイン</a>
            </li>
            <li>
                <a href="/ingredients/dokudami">ドクダミエキス</a>
            </li>
        </ul>
    );
};

export {};
