import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "ラ ロッシュ ポゼ",
    name,
    folder: "laroche-posay",
    image,
    comment,
    type,
    ...extraProps,
});

export const PeelCareSerum = createProduct(
    "PeelCareSerum",
    "美容液",
    "エファクラ ピールケア セラム",
    "",
    {
        amazon: "https://amzn.to/3zqJGCP",
        qoo10: "https://www.qoo10.jp/su/1419182861/Q192046927",
        ingredients: "ナイアシンアミドグリセリン",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">角質ケアにはサンソリットの黒石鹸、ラロッシュポゼのエファクラ ピールケア セラ素晴らしいと思う🥺週1.2くらいで使う。黒石鹸は体にも使うと肌がまじでくすみとれて綺麗なる。それぞれ5000円くらいだが年間計算だと２万いくか行かないか😍💁‍♀️ <a href="https://t.co/wIKF0CtodY">pic.twitter.com/wIKF0CtodY</a></p>&mdash; 雲丹肉ちゃん (@oniku117) <a href="https://twitter.com/oniku117/status/1569667961077760000?ref_src=twsrc%5Etfw">September 13, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);
