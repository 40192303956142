import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "COSRX(コスアールエックス)",
    name,
    folder: "cosrx",
    image,
    comment,
    type,
    ...extraProps,
});

export const TheLetinol = createProduct(
    "TheLetinol",
    "クリーム",
    "RXザ・レチノール0.1クリーム",
    `本物の純粋レチノール含有で実際変わってくるお肌の変化をご体験ください。
    ノンコメドジェニックテスト済みなので敏感肌やニキビ肌の方も安心！`,
    {
        ingredients: "レチノールヒアルロン酸アラントイングルタチオン",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">肌がツヤツヤな友達に何使ってるの？って聞いたら、COSRXのレチノールを毎晩、化粧水と美容液を塗った後に薄く顔全体に使っていると言っていた。綺麗な人が使っているものは真似するスタイルなので、即購入した。 <a href="https://t.co/oBFz1B9WhO">pic.twitter.com/oBFz1B9WhO</a></p>&mdash; さあちゃん (@saachan0013) <a href="https://twitter.com/saachan0013/status/1671521316837490691?ref_src=twsrc%5Etfw">June 21, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        features: "ノンコメドジェニック",
        qoo10: "https://www.qoo10.jp/su/1419182425/Q192046927",
        amazon: "https://amzn.to/3W6vd8b",
    }
);
