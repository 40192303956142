import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "イニスフリー",
    name,
    folder: "innisfree",
    image,
    comment,
    type,
    ...extraProps,
});

export const EyenFaceBowl = createProduct(
    "EyenFaceBowl",
    "アイクリーム",
    "アイ＆フェイスボール",
    "",
    {
        ingredients: "ヒアルロン酸",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">前にTLで見て即ぽちったInnisfreeのアイ＆フェイスボール。これ本当に良い。朝目元むくんでまぶたぼってりなるタイプなんだけど寝る前にこれ使ったら翌朝目元すっきりで助かる...ころころマッサージ出来るの気持ちいい。リピート3本目です。<a href="https://twitter.com/hashtag/%E4%BD%BF%E3%81%84%E5%88%87%E3%82%8A%E3%82%B9%E3%82%AD%E3%83%B3%E3%82%B1%E3%82%A2?src=hash&amp;ref_src=twsrc%5Etfw">#使い切りスキンケア</a> <a href="https://t.co/ZnnvZoBuCj">pic.twitter.com/ZnnvZoBuCj</a></p>&mdash; るるちゃん (@skstibnni) <a href="https://twitter.com/skstibnni/status/1310783534420733953?ref_src=twsrc%5Etfw">September 29, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        qoo10: "https://www.qoo10.jp/su/1419182802/Q192046927",
        amazon: "https://amzn.to/4bpN0eL",
    }
);

export const RetinolCicaSerum = createProduct(
    "RetinolCicaSerum",
    "美容液",
    "レチノール シカ リペア セラム",
    "",
    {
        ingredients: "レチノールシカナイアシンアミドヒト型セラミド",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">1ヶ月ぶりに会った親友、前より肌がつやんつやんでしかも今日ファンデしてないって言うからびっくりしたんだけど例のイニスフリーのレチノール使い始めたらしい<br>ニキビ跡も消えたって言ってたしいいこと聞いた..私も買う <a href="https://t.co/pGidfOZMQL">pic.twitter.com/pGidfOZMQL</a></p>&mdash; 甘い (@_daydreaming_zZ) <a href="https://twitter.com/_daydreaming_zZ/status/1647590989346000899?ref_src=twsrc%5Etfw">April 16, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        qoo10: "https://www.qoo10.jp/su/1418090442/Q192046927",
    }
);
