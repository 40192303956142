import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "VT",
    name,
    folder: "vt",
    image,
    comment,
    type,
    ...extraProps,
});

export const ReedleShot = createProduct("ReedleShot", "美容液", "リードルショット", "", {
    ingredients: "ナイアシンアミド",
});

export const CicaRetiEssence = createProduct(
    "CicaRetiEssence",
    "美容液",
    "シカレチA エッセンス0.1",
    `水分たっぷりの軽い使用感でベタつかずさっぱりした高濃縮エッセンス。皮脂を抑えて毛穴の目立たない肌に導きます。`,
    {
        qoo10: "https://www.qoo10.jp/su/1419182912/Q192046927",
        ingredients: "レチノールアラントインナイアシンアミドヒト型セラミド",
    }
);

export const MildToner = createProduct(
    "MildToner",
    "トナーパッド",
    "CICA マイルドトナーパッド",
    `これ一つで簡単時短マルチケアができるトナーパッドです。角質ケア+ふき取り化粧水+肌のキメを整えます。ピンセット付きで衛生的です。60枚入り。`,
    {
        ingredients: "ヒアルロン酸アラントイングリチルリチン酸2Kツボクサエキス",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">VTのシカトナーパッド<br>スキンケアの初めに拭き取りを使うようにしてからニキビ肌がかなり改善されたので本当おすすめ。パッドは楽で、ピンセット付で衛生的。<br>拭き取り化粧水で肌のひりつきやアルコール感が気になる方は弱酸性のこちらも良いかも☺️写真は使用して2日目の時。次の写真は閲覧注意です🙇‍♂️ <a href="https://t.co/ynipdB55M9">pic.twitter.com/ynipdB55M9</a></p>&mdash; asami (@asamiffee) <a href="https://twitter.com/asamiffee/status/1284810603203186695?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        qoo10: "https://www.qoo10.jp/su/1418258864/Q192046927",
    }
);
