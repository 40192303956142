import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "セザンヌ",
    name,
    folder: "cezanne",
    image,
    comment,
    type,
    ...extraProps,
});

export const NaturalLotion = createProduct("NaturalLotion", "化粧水", "ナチュラルローション", "", {
    ingredients: "ドクダミエキスグリチルリチン酸2Kナイアシンアミドヒアルロン酸",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">ついにあのセザンヌからドクダミ化粧水が出た❗️しかもこれ715円ってやばくない？🥹実際に1週間使ったんだけど、さっぱりしててベタつかないし拭き取り化粧水としてもボディケアとしても使えちゃうから万能すぎる、、、しかも360mLと大容量だから惜しみなくバシャバシャ使えちゃうのも良い🥳💗 <a href="https://t.co/c757UB6zwK">pic.twitter.com/c757UB6zwK</a></p>&mdash; めるも (@melt__in) <a href="https://twitter.com/melt__in/status/1570353578581307397?ref_src=twsrc%5Etfw">September 15, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    amazon: "https://amzn.to/3W3575R",
});
