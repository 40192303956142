import React from "react";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
    const location = useLocation();

    return (
        <header>
            <div className="container">
                <a href="/">
                    <span className="sub">スキンケアを成分から考える</span>
                    {location.pathname === "/" ? (
                        <h1 className="site-ttl">素肌研究所</h1>
                    ) : (
                        <span className="site-ttl">素肌研究所</span>
                    )}
                </a>
            </div>
        </header>
    );
};

export default Header;
