interface Product {
    brand: string;
    name: string;
    folder: string;
    image: string;
    comment: string;
    ingredients?: string;
    twitterEmbed?: string;
    type: string;
    qoo10?: string;
    amazon?: string;
    rakuten?: string;
    yahoo?: string;
}

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "エリクシール",
    name,
    folder: "elixir",
    image,
    comment,
    type,
    ...extraProps,
});

export const BrighteningLotion = createProduct(
    "BrighteningLotion",
    "化粧水",
    "ブライトニング ローション WT Ⅱ",
    "",
    {
        ingredients: "4MSKグリチルリチン酸2Kジカリウム",
    }
);

export const WrinkleCream = createProduct(
    "WrinkleCream",
    "アイケア",
    "シュペリエル エンリッチド リンクルクリーム",
    "",
    {
        ingredients: "レチノール",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">実家帰ったら母が落ち込んでて。花粉で肌がやられてるから流石にレチノールは…ってことでエリクシールのアイクリームやめたら目の下に皺ができてたらしい。しかも2本。還暦越えでもシワ作らせないってどないなってんのエリクシール…君の居場所、ドラストじゃなくて百貨店だよ <a href="https://t.co/Vz2rMAMmOH">pic.twitter.com/Vz2rMAMmOH</a></p>&mdash; 丸の内OLの憂鬱 (@marunouchi__ol_) <a href="https://twitter.com/marunouchi__ol_/status/1762781572401676684?ref_src=twsrc%5Etfw">February 28, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    }
);
