// src/pages/NotFoundPage.tsx
import React from "react";

const NotFoundPage: React.FC = () => {
    return (
        <div className="lg-inner">
            <h1 className="ttl-ul">404エラー</h1>
            <p>このページは存在しません。</p>
        </div>
    );
};

export default NotFoundPage;
