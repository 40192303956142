import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "魔女工房",
    name,
    folder: "majyo",
    image,
    comment,
    type,
    ...extraProps,
});

export const ComplexSerum = createProduct(
    "ComplexSerum",
    "美容液",
    "ビフィダバイオームコンプレックスセラム",
    "",
    {
        ingredients: "ヒアルロン酸ナイアシンアミド",
        qoo10: "https://www.qoo10.jp/su/1418088975/Q192046927",
        amazon: "https://amzn.to/3xRAxCK",
    }
);

export const GalacNiacinEssence = createProduct(
    "GalacNiacinEssence",
    "美容液",
    "ガラクナイアシン2.0エッセンス",
    "",
    {
        qoo10: "https://www.qoo10.jp/su/1419182786/Q192046927",
        amazon: "https://amzn.to/4cNcPGV",
        ingredients: "ヒアルロン酸ナイアシンアミド",
    }
);
