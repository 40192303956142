// src/pages/AboutPage.tsx

import React from "react";
import { ProductTable } from "../../components/ProductTable";
import { BalancingGA } from "../../data/toutvert";

const RetinolPage: React.FC = () => {
    return (
        <div className="products-page lg-inner">
            <h1>アゼライン酸</h1>
            <div className="lead">
                {/* 
 <h2 className="ttl-ul">主な働き</h2>

                <h2 className="ttl-ul">こんな肌悩みにおすすめ</h2>
                      lead 
                <ul className="problem-list">
                    <li>赤み</li>
                    <li>ニキビ</li>
                    <li>小ジワ</li>
                </ul>*/}
            </div>

            <h2 className="ttl-ul">アゼライン酸を含んだ製品一覧</h2>
            <ul className="products-list">
                <ProductTable product={BalancingGA} />
            </ul>
        </div>
    );
};

export default RetinolPage;
