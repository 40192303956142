import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
): Product => ({
    brand: "Anua",
    name,
    folder: "anua",
    image,
    comment,
    type,
    ...extraProps,
});

export const SoothingToner = createProduct(
    "SoothingToner",
    "化粧水",
    "ドクダミ77% スージングトナー",
    `軽やかなとろみテクスチャーですみずみまですっとなじむ化粧水です。不要なものを取り除き、こだわった自然由来成分をたっぷり詰め込みました。
敏感肌やニキビ肌の方も安心して使えます。`,
    {
        ingredients: "ヒアルロン酸ドクダミエキス",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">momohoちゃんが紹介してたanuaのドクダミ化粧水。私も肌が弱く混合肌で、夏の気温と湿度が高くなるこの時期、皮脂の分泌が多くなりがちなので、ドクダミ77％のさっぱりとした軽やかな化粧水で肌表面の余分な油分をふき取るのが肌荒れ防止に不可欠… <a href="https://t.co/lgcVSpdS11">pic.twitter.com/lgcVSpdS11</a></p>&mdash; 𝙃𝙞𝙣𝙖 (@b55fq) <a href="https://twitter.com/b55fq/status/1666030246775382016?ref_src=twsrc%5Etfw">June 6, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        features: "無着色無香料無鉱物油界面活性剤不使用アルコールフリーパラベンフリー",
        qoo10: "https://www.qoo10.jp/su/1418233759/Q192046927",
    }
);

export const AnuaCleansingOil = createProduct(
    "AnuaCleansingOil",
    "クレンジングオイル",
    "ドクダミポアコントロールクレンジングオイル",
    "",
    {
        type: "オイルクレンジング",
        ingredients:
            "グリシルグリシンナイアシンアミドビタミンＣ誘導体APPSヒト型セラミドヒアルロン酸",
        twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">バズってないがバズってもいいのではと思ってるコスメ「プラスggベースセラムローション」<br>毛穴目立ちは毛穴周りの皮膚の肌荒れから。毛穴ケアにエビデンスのあるグリシルグリシン6.6%配合の先行美容液。ナイアシンアミドにヒト型セラミドアゼライン酸誘導体まで入ってなかなか良いのに知名度低すぎ。 <a href="https://t.co/qTtKH2MmOA">pic.twitter.com/qTtKH2MmOA</a></p>&mdash; ななな｜脱ファンデ計画 (@sonokyomunikiku) <a href="https://twitter.com/sonokyomunikiku/status/1707223107914887615?ref_src=twsrc%5Etfw">September 28, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        amazon: "https://amzn.to/3RODX01",
        qoo10: "https://www.qoo10.jp/su/1419182290/Q192046927",
    }
);

export const DarkSpotSerum = createProduct("DarkSpotSerum", "美容液", "ダークスポットセラム", "", {
    type: "美容液",
    ingredients: "ナイアシンアミドトラネキサム酸ヒアルロン酸パンテノールヒト型セラミドアルブチン",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">バズってないがバズってもいいのではと思ってるコスメ「プラスggベースセラムローション」<br>毛穴目立ちは毛穴周りの皮膚の肌荒れから。毛穴ケアにエビデンスのあるグリシルグリシン6.6%配合の先行美容液。ナイアシンアミドにヒト型セラミドアゼライン酸誘導体まで入ってなかなか良いのに知名度低すぎ。 <a href="https://t.co/qTtKH2MmOA">pic.twitter.com/qTtKH2MmOA</a></p>&mdash; ななな｜脱ファンデ計画 (@sonokyomunikiku) <a href="https://twitter.com/sonokyomunikiku/status/1707223107914887615?ref_src=twsrc%5Etfw">September 28, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    amazon: "https://amzn.to/45LBEQY",
    qoo10: "https://www.qoo10.jp/su/1419182303/Q192046927",
});
