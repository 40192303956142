import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { ProductTable } from "../../components/ProductTable";
import { filterProducts } from "../../components/FilterProducts";
import { Product } from "../../interfaces/Product";
import * as productData from "./VitamincData";

const RetinolPage: React.FC = () => {
    const pageTitle = "ビタミンC";
    const products: Product[] = Object.values(productData);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
    const [activeFilter, setActiveFilter] = useState<string>("all");

    const handleShowAll = () => {
        setFilteredProducts(filterProducts.showAll(products));
        setActiveFilter("all");
    };

    const handleFilterByMist = () => {
        setFilteredProducts(filterProducts.filterByToner(products));
        setActiveFilter("toner");
    };

    const handleFilterBySerum = () => {
        setFilteredProducts(filterProducts.filterBySerum(products));
        setActiveFilter("serum");
    };

    return (
        <>
            <PageTitle title={pageTitle} />
            <div className="products-page lg-inner">
                <h1>{pageTitle}</h1>
                <div className="lead">
                    <h2 className="ttl-ul">ビタミンCの主な働き</h2>
                    <span className="_bold">抗酸化作用</span>
                    ダメージから肌を守る効果があるので、シミやしわ予防に。
                    <span className="_bold mt">肌にハリを与える</span>
                    肌にハリが出て引き締まり、毛穴を引き締める。
                    <span className="_bold mt">透明感</span>
                    明るく透明感のある肌に導く。
                    <span className="_bold mt">紫外線対策</span>
                    紫外線による肌のダメージを軽減する効果が期待され、日焼け後のケアにおすすめ。
                    <h2>こんな肌悩みにおすすめ</h2>
                    <ul className="problem-list">
                        <li>くすみ･シミ</li>
                        <li>ハリ不足</li>
                        <li>日焼け対策</li>
                    </ul>
                </div>
                {/* lead */}

                <h2 className="ttl-ul">{`${pageTitle}を含んだ製品一覧`}</h2>

                <div className="filters">
                    <button
                        onClick={handleShowAll}
                        className={activeFilter === "all" ? "active" : ""}
                    >
                        すべて
                    </button>
                    <button
                        onClick={handleFilterByMist}
                        className={activeFilter === "toner" ? "active" : ""}
                    >
                        化粧水
                    </button>
                    <button
                        onClick={handleFilterBySerum}
                        className={activeFilter === "serum" ? "active" : ""}
                    >
                        美容液
                    </button>
                </div>

                <ul className="products-list">
                    {filteredProducts.map((product, index) => (
                        <ProductTable key={index} product={product} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default RetinolPage;
