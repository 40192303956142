import { Product } from "../interfaces/Product";

const createProduct = (
    image: string,
    type: string,
    name: string,
    comment: string,
    extraProps?: Partial<Product>
) => ({
    brand: "トリデン",
    name,
    folder: "torriden",
    image,
    comment,
    type,
    ...extraProps,
});

export const DiveInSerum = createProduct("diveInSerum", "美容液", "ダイブイン セラム", "", {
    ingredients: "ヒアルロン酸アラントインヒト型セラミド",
    twitterEmbed: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="ja" dir="ltr">&quot;3秒セラム&quot;こと、Torriden(トリデン)のダイブインセラム。とろ〜んとしてるように見えるけど、馴染ませるとサラッとしてベタつかない韓国人が好きそうなテクスチャ(私も好き)。スーッと肌に入ってしっとりモチモチに…こりゃ人気だわ。肌調子に合わせて2〜3回重ね付けでしっかり水分チャージできる🚰 <a href="https://t.co/IBFgDwM7gW">pic.twitter.com/IBFgDwM7gW</a></p>&mdash; らむね (@mrmrmron7) <a href="https://twitter.com/mrmrmron7/status/1398873625910939649?ref_src=twsrc%5Etfw">May 30, 2021</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
    qoo10: "https://www.qoo10.jp/su/1418089707/Q192046927",
    amazon: "https://amzn.to/3RSqOD3",
});
